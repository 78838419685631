import { initializeApp, FirebaseApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getValue, RemoteConfig } from 'firebase/remote-config';
import React, { useState, useEffect, ReactNode } from 'react';

interface Flags {
  [key: string]: boolean;
}

interface FlagsProviderProps {
  defaults: Flags;
  children: ReactNode;
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || ''
};

// Initialize Firebase app
const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

// Initialize Remote Config
const remoteConfig: RemoteConfig = getRemoteConfig(firebaseApp);

// Remote Config settings
remoteConfig.settings = {
  minimumFetchIntervalMillis: 1, // 1 minute
  fetchTimeoutMillis: 60000, // 1 minute
};

// Context for flags
const FlagsContext = React.createContext<Flags>({});

const FlagsProvider: React.FC<FlagsProviderProps> = ({ defaults, children }) => {
  const [flags, setFlags] = useState<Flags>(defaults);

  useEffect(() => {
    let isMounted = true;

    const fetchFlags = async () => {
      try {
        const activated = await fetchAndActivate(remoteConfig);
        if (!activated) {
          console.log('Remote Config was not activated. Using defaults.');
        }

        // Fetch individual flag values from Remote Config
        const addStaffFlag = getValue(remoteConfig, 'is_add_staff').asBoolean() ?? defaults.addStaff;
        console.log('Fetched is_add_staff:', addStaffFlag);

        // Set from Remote Config
        const newFlags: Flags = {
          addStaff: addStaffFlag, 
        };

        if (isMounted) {
          setFlags (newFlags);
        }
        console.log('Fetched Feature:', addStaffFlag);
      } catch (err) {
        console.error('Error fetching remote config:', err);
        if (isMounted) {
          setFlags(defaults); 
        }
      }
    };

    fetchFlags();

    return () => {
      isMounted = false;
    };
  }, [defaults]);

  return (
    <FlagsContext.Provider value={flags}>
      {children}
    </FlagsContext.Provider>
  );
};

export { FlagsProvider, FlagsContext };
