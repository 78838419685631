import './assets/scss/theme.scss';
import {
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { updateBreakpoint } from './store/screenSize';
import { getBreakpoint } from './utils/useAppBreakpoint';
import { FlagsProvider } from './providers/firebase/firebaseProvider';

import routes from './routes/routes';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const setWindowMediaSize = () => {
    const pageDetails = {
      breakpoint: getBreakpoint(),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };

    dispatch(updateBreakpoint(pageDetails));
  };

  useEffect(() => {
    setWindowMediaSize();
    window.addEventListener(
      'resize',
      () => {
        setWindowMediaSize();
      },
      false
    );

    return () => window.removeEventListener('resize', setWindowMediaSize);
  }, []);

  const router = createBrowserRouter(routes);
  const defaultFlags = { addStaff: true };

  return (
    <React.Fragment>
      <FlagsProvider defaults={defaultFlags}>
        <RouterProvider router={router} />
      </FlagsProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = (state: any) => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
