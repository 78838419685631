import './index.scss';
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface CurrencyTextInputProps {
  amount: string;
  onChange: (value: string) => void;
  label?: string;
  invalid?: boolean;
  feedback?: string;
  testId?: string;
  nameInput?: string;
  onBlurInput?: () => void;
  onFocusInput?: () => void;
  disabled?: boolean;
  leftInputTextLabel?: string;
  rightInputTextLabel?: string;
  placeholder?: string;
  decimalScale?: number;
  customClass?: string;
}

// Utility function to generate class names
const getClassNames = (
  baseClass: string,
  isActive: boolean,
  invalid: boolean,
  disabled: boolean
) => `${baseClass} ${isActive ? 'active' : ''} ${invalid ? 'invalid' : ''} ${disabled ? 'disabled' : ''}`;

// Subcomponent for Label
const CurrencyLabel = ({
  label,
  isActive,
  invalid,
}: {
  label: string;
  isActive: boolean;
  invalid: boolean;
}) => (
  <Label
    data-testid="currencyTextLabel"
    className={getClassNames('currency-text-input__label', isActive, invalid, false)}
  >
    {label}
  </Label>
);

// Subcomponent for InputGroupText
const InputGroupLabel = ({
  text,
  isActive,
  invalid,
  disabled,
}: {
  text: string;
  isActive: boolean;
  invalid: boolean;
  disabled: boolean;
}) => (
  <InputGroupText
    data-testid="currencyTextIconWrapper"
    className={getClassNames('currency-text-input__simbol-wrapper', isActive, invalid, disabled)}
  >
    {invalid && !isActive ? <ErrorOutlineIcon className="currency-text-input__icon" /> : text}
  </InputGroupText>
);

const CurrencyTextInput: React.FC<CurrencyTextInputProps> = ({
  amount,
  onChange,
  label = 'Send Amount',
  invalid = false,
  feedback,
  testId = 'currencyTextInput',
  nameInput,
  onBlurInput,
  onFocusInput,
  disabled = false,
  leftInputTextLabel = '$',
  rightInputTextLabel = '',
  placeholder = '0.00',
  decimalScale = 2,
  customClass = '',
}) => {
  const [inputTouched, setInputTouched] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const onBlurHandler = () => {
    setIsActive(false);
    if (!inputTouched) setInputTouched(true);
    onBlurInput?.();
  };

  const onFocusHandler = () => {
    setIsActive(true);
    onFocusInput?.();
  };

  return (
    <FormGroup className="currency-text-input">
      {label && <CurrencyLabel label={label} isActive={isActive} invalid={invalid && !isActive} />}
      <InputGroup className={`${customClass}`}>
        {leftInputTextLabel && (
          <InputGroupLabel
            text={leftInputTextLabel}
            isActive={isActive}
            invalid={invalid && !isActive}
            disabled={disabled}
          />
        )}
        <NumberFormat
          name={nameInput}
          value={amount}
          customInput={Input}
          thousandSeparator={true}
          className={`currency-text-input__input-amount ${rightInputTextLabel ? 'with-right-icon' : ''} ${leftInputTextLabel ? 'with-left-icon' : ''} ${disabled && 'disabled'}`}
          onValueChange={({ value }) => onChange(value)}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          decimalScale={decimalScale}
          data-testid={testId}
          placeholder={placeholder}
          invalid={invalid && !isActive}
          disabled={disabled}
        />
        {rightInputTextLabel && (
          <InputGroupLabel
            text={rightInputTextLabel}
            isActive={isActive}
            invalid={invalid && !isActive}
            disabled={disabled}
          />
        )}
        <FormFeedback>{feedback}</FormFeedback>
      </InputGroup>
    </FormGroup>
  );
};

export default CurrencyTextInput;
